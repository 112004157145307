import React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import Section from '../../../../Core/Layout/Section/Section'
import EightBitEnvImg from './EightBitEnvImg/EightBitEnvImg'
import { HtmlDiv } from '../../../../../utils/types'

const EightBitEnv = ({ className, ...rest }: EightBitEnvProps) => {
  return (
    <div className={` ${className}`} {...rest}>
      <Section className="flex flex-wrap lg:justify-between justify-center items-center">
        <div className="eight-bit-env-description">
          <h2 className="underline mb-0">
            <a
              href="https://github.com/Dev-Simulator/8-bit-env"
              target="_BLANK"
              rel="noreferrer"
            >
              8 Bit Env
            </a>
          </h2>
          <div className="font-sm text-accent mb-4">
            manage your environment variables
          </div>
          <div>
            It’s the easiest way to keep track of the environment variables in
            your app.
            <br />
            <br />
            Using a stylish cli, 8 Bit Env will encrypt and manage your .env
            files, allowing you to check them into version contol and easily
            switch environments!
            <br />
            <br />
            It works in development setups and CI/CD pipelines.
          </div>
        </div>
        <EightBitEnvImg className="env-img flex-grow my-4 mx-4" />
      </Section>
    </div>
  )
}

export interface EightBitEnvProps extends HtmlDiv {}

export default styled(observer(EightBitEnv))`
  .env-img {
    max-width: 30rem;
    min-width: 15rem;
  }

  .eight-bit-env-description {
    max-width: 40rem;
  }
`
