import React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import Section from '../../../../Core/Layout/Section/Section'
import OpenSourceLogo from './OpenSourceLogo/OpenSourceLogo'
import { HtmlDiv } from '../../../../../utils/types'

const Banner = ({ className, ...rest }: BannerProps) => {
  return (
    <div className={`pt-12 pb-12 ${className}`} {...rest}>
      <Section className="flex flex-wrap lg:justify-between justify-center items-center">
        <div className="flex flex-col w-full logo-container">
          <div className="font-bold text-bold">we got</div>
          <OpenSourceLogo className="logo w-full" />
        </div>
        <div className="os-text text-lg">
          <h2 className="mb-4">Dev Simulator OS Initiative</h2>
          The Dev Simulator team is committed to giving back to the community!
          <br />
          <br />
          As we build out the game platform and systems, we’ll be open sourcing
          as much code as we can.
          <br />
          <br />
          If you’d like to see what we’re doing or maybe even contribute, check
          out our current projects below!
        </div>
      </Section>
    </div>
  )
}

export interface BannerProps extends HtmlDiv {}

export default styled(observer(Banner))`
  .logo-container {
    max-width: 30rem;
  }

  .logo {
    max-width: 30rem;
  }
  .os-text {
    max-width: 35rem;
  }
`
